<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <b>
          {{
            inputs.language === 'en'
              ? 'Select the best answer'
              : 'Sélectionnez la meilleure réponse'
          }}
        </b>
        {{
          inputs.language === 'en'
            ? 'Name the functional groups that are circled in the structure shown below'
            : 'Nommez les groupes fonctionnels encerclés dans la structure suivante :'
        }}
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 210px" :src="imageName" />
      </p>

      <v-select
        v-model="inputs.groupA"
        :items="items1"
        item-text="text"
        item-value="value"
        label="Groupe A:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.groupB"
        :items="items2"
        item-text="text"
        item-value="value"
        label="Groupe B:"
        class="mb-n2"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question492',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        groupA: null,
        groupB: null,
        language: 'fr',
      },
      items1: [
        {text: 'Amine', value: 'amine'},
        {text: 'Cétone', value: 'ketone'},
        {text: 'Alcool', value: 'alcohol'},
        {text: 'Nitrile', value: 'nitrile'},
        {text: 'Amide', value: 'amide'},
        {text: 'Aldéhyde', value: 'aldehyde'},
        {text: 'Estère', value: 'ester'},
      ],
      items2: [
        {text: 'Alcane', value: 'alkane'},
        {text: 'Alcène', value: 'alkene'},
        {text: 'Alcyne', value: 'alkyne'},
        {text: 'Cycle aromatique', value: 'aromatic'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/functionalGroupIdentification1.png';
    },
  },
};
</script>
<style scoped></style>
