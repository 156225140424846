var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-4"},[_c('b',[_vm._v(" "+_vm._s(_vm.inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse')+" ")]),_vm._v(" "+_vm._s(_vm.inputs.language === 'en' ? 'Name the functional groups that are circled in the structure shown below' : 'Nommez les groupes fonctionnels encerclés dans la structure suivante :')+" ")]),_c('p',{staticClass:"pl-8 mb-4"},[_c('v-img',{staticStyle:{"max-width":"210px"},attrs:{"src":_vm.imageName}})],1),_c('v-select',{staticClass:"mb-0",staticStyle:{"width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Groupe A:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.groupA),callback:function ($$v) {_vm.$set(_vm.inputs, "groupA", $$v)},expression:"inputs.groupA"}}),_c('v-select',{staticClass:"mb-n2",staticStyle:{"width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Groupe B:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.groupB),callback:function ($$v) {_vm.$set(_vm.inputs, "groupB", $$v)},expression:"inputs.groupB"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }